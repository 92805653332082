<template>
    <div style="width:100%;">
        <el-table :data="rooms">
            <el-table-column label="讲座名称" prop="name"></el-table-column>
            <el-table-column label="学科" prop="event_name"></el-table-column>
            <el-table-column label="开始时间">
                <template slot-scope="scope">
                    {{formateDate(scope.row.event_start_time)}}
                </template>
            </el-table-column>
            <el-table-column label="结束时间">
                <template slot-scope="scope">
                    {{formateDate(scope.row.event_end_time)}}
                </template>
            </el-table-column>
            <el-table-column label="操作">
                <template slot-scope="scope">
                    <el-button @click="toLecture(scope.row)" type="primary" size="medium">进入讲座</el-button>
                </template>
            </el-table-column>
        </el-table>
    </div>
</template>

<script>
import {getEventsByEmployer,checkZhiboOpen} from '../../api/keneng'
import {getUserId} from '../../utils/store'
export default {
    data(){
        return{
            rooms:[]
        }
    },
    mounted(){
        this.fetchData()
    },
    methods:{
        fetchData(){
            getEventsByEmployer().then(res=>{
                if(res.data.code===0){
                    this.rooms = res.data.data.event_rooms
                }
            })
        },
        formateDate(time){
            let newTime = new Date(time*1000)
            return `${newTime.getFullYear()}-${newTime.getMonth()+1}-${newTime.getDate()} ${newTime.getHours()}:${newTime.getMinutes()>9?newTime.getMinutes():'0'+newTime.getMinutes()}`
        },
        toLecture(item){
            // checkZhiboOpen(item.project_id,item.event_name).then(res=>{
            //     if(res.data.code===0){
            //         let url = this.$router.resolve({
            //             path:'/employee/lecturescreen',
            //             query:{
            //                 roomId:res.data.data.event_meeting_room_ids[0]
            //             }
            //         })
            //         window.open(url.href,'_blank')
            //     }else{
            //         this.$message(res.data.msg)
            //     }
            // })
            let url = this.$router.resolve({
                path:'/employee/lecturescreen',
                query:{
                    roomId:item.event_meeting_room_ids[0]
                }
            })
            window.open(url.href,'_blank')
        }
    }
}
</script>

<style>

</style>